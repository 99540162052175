/*
  Icon Font: font-logos
*/

@font-face {
  font-family: "font-logos";
  src: url("./font-logos.eot");
  src: url("./font-logos.eot?#iefix") format("embedded-opentype"),
       url("./font-logos.woff2") format("woff2"),
       url("./font-logos.woff") format("woff"),
       url("./font-logos.ttf") format("truetype"),
       url("./font-logos.svg#font-logos") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "font-logos";
    src: url("./font-logos.svg#font-logos") format("svg");
  }
}

.fl {
}



.fl-12 { font-size: 12px; }

.fl-14 { font-size: 14px; }

.fl-16 { font-size: 16px; }

.fl-18 { font-size: 18px; }

.fl-21 { font-size: 21px; }

.fl-24 { font-size: 24px; }

.fl-36 { font-size: 36px; }

.fl-48 { font-size: 48px; }

.fl-60 { font-size: 60px; }

.fl-72 { font-size: 72px; }


[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.fl-almalinux:before,
.fl-alpine:before,
.fl-aosc:before,
.fl-apple:before,
.fl-archlabs:before,
.fl-archlinux:before,
.fl-artix:before,
.fl-budgie:before,
.fl-centos:before,
.fl-coreos:before,
.fl-debian:before,
.fl-deepin:before,
.fl-devuan:before,
.fl-docker:before,
.fl-elementary:before,
.fl-fedora:before,
.fl-fedora-inverse:before,
.fl-ferris:before,
.fl-flathub:before,
.fl-freebsd:before,
.fl-gentoo:before,
.fl-gnu-guix:before,
.fl-illumos:before,
.fl-kali-linux:before,
.fl-linuxmint:before,
.fl-linuxmint-inverse:before,
.fl-mageia:before,
.fl-mandriva:before,
.fl-manjaro:before,
.fl-nixos:before,
.fl-openbsd:before,
.fl-opensuse:before,
.fl-pop-os:before,
.fl-raspberry-pi:before,
.fl-redhat:before,
.fl-rocky-linux:before,
.fl-sabayon:before,
.fl-slackware:before,
.fl-slackware-inverse:before,
.fl-snappy:before,
.fl-solus:before,
.fl-tux:before,
.fl-ubuntu:before,
.fl-ubuntu-inverse:before,
.fl-void:before,
.fl-zorin:before {
  display: inline-block;
  font-family: "font-logos";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fl-almalinux:before { content: "\f100"; }
.fl-alpine:before { content: "\f101"; }
.fl-aosc:before { content: "\f102"; }
.fl-apple:before { content: "\f103"; }
.fl-archlabs:before { content: "\f104"; }
.fl-archlinux:before { content: "\f105"; }
.fl-artix:before { content: "\f106"; }
.fl-budgie:before { content: "\f107"; }
.fl-centos:before { content: "\f108"; }
.fl-coreos:before { content: "\f109"; }
.fl-debian:before { content: "\f10a"; }
.fl-deepin:before { content: "\f10b"; }
.fl-devuan:before { content: "\f10c"; }
.fl-docker:before { content: "\f10d"; }
.fl-elementary:before { content: "\f10e"; }
.fl-fedora:before { content: "\f10f"; }
.fl-fedora-inverse:before { content: "\f110"; }
.fl-ferris:before { content: "\f111"; }
.fl-flathub:before { content: "\f112"; }
.fl-freebsd:before { content: "\f113"; }
.fl-gentoo:before { content: "\f114"; }
.fl-gnu-guix:before { content: "\f115"; }
.fl-illumos:before { content: "\f116"; }
.fl-kali-linux:before { content: "\f117"; }
.fl-linuxmint:before { content: "\f118"; }
.fl-linuxmint-inverse:before { content: "\f119"; }
.fl-mageia:before { content: "\f11a"; }
.fl-mandriva:before { content: "\f11b"; }
.fl-manjaro:before { content: "\f11c"; }
.fl-nixos:before { content: "\f11d"; }
.fl-openbsd:before { content: "\f11e"; }
.fl-opensuse:before { content: "\f11f"; }
.fl-pop-os:before { content: "\f120"; }
.fl-raspberry-pi:before { content: "\f121"; }
.fl-redhat:before { content: "\f122"; }
.fl-rocky-linux:before { content: "\f123"; }
.fl-sabayon:before { content: "\f124"; }
.fl-slackware:before { content: "\f125"; }
.fl-slackware-inverse:before { content: "\f126"; }
.fl-snappy:before { content: "\f127"; }
.fl-solus:before { content: "\f128"; }
.fl-tux:before { content: "\f129"; }
.fl-ubuntu:before { content: "\f12a"; }
.fl-ubuntu-inverse:before { content: "\f12b"; }
.fl-void:before { content: "\f12c"; }
.fl-zorin:before { content: "\f12d"; }
